body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-height {
  height: 100%;
}

#vector-map {
  height: 100%;
}

#tooltip {
  color: black
}

#tooltip-image {
  width: 50px;
  border: 1px;
  border-color: #000000;
  border-style: solid;
  margin-bottom: 10px;
}

#footer {
  background: #121a21;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

#dx-img {
  height: 15px;
  vertical-align: middle;
}

#source {  
  position: fixed;
  bottom: 50px;
  right: 10px;
  width: 100%;
  color: #bfbfbf;
  text-align: right;
}

a#source-link {
  color: #bfbfbf;
  text-decoration: none;
}

a.copyright-link {
  color: #ff8f00;
  text-decoration: none;
}

a:hover, a:focus {
  opacity: 0.8;
}